import React from 'react';
import "./pricing_pages.css";
import { PricingTables } from './pricing_tables';

export const PricingPage = () => {
	return (
		<div className="pricing-pages">
			<h3> Tarifs </h3>

			<PricingTables />
		</div>
	)
}