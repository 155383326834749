import React, { Component } from 'react';
import { SmallLoadingComponent } from '../../common/loading_component';
import { PricingService } from '../../services/pricing_service';

const getTableBody = (data) => {
	if (!data) {
		return [];
	}
	return data.map(entry => {
		const nameString = entry.name.substring(0, 50);
		return (
			<tr>
				<td>{nameString}{nameString.length !== entry.name.length ? "..." : ""}</td>
				<td>{entry.active_year}</td>
				<td>{entry.price}€</td>
			</tr>
		)
	});
}

const QuatwayPricingTable = (props) => {
	return (
		<div>
			<h4>Adhésion annuelle</h4>
			<table className="table pricing-table">
				<thead className="thead-cng">
					<tr>
						<th scope="col">Dimensions</th>
						<th scope="col">Saison</th>
						<th scope="col">Tarif</th>
					</tr>
				</thead>
				<tbody>
					{getTableBody(props.data)}
				</tbody>
			</table>
			{props.isLoading ? <SmallLoadingComponent /> : <></>}
		</div>
	)
}



const TemporaryMemberPricingTable  = (props) => {
	return (
		<div>
			<h4>Adhésion temporaire</h4>
			<table className="table pricing-table">
				<thead className="thead-cng">
					<tr>
						<th scope="col">Type</th>
						<th scope="col">Saison</th>
						<th scope="col">Tarif</th>
					</tr>
				</thead>
				<tbody>
					{getTableBody(props.data)}
				</tbody>
			</table>
			{props.isLoading ? <SmallLoadingComponent /> : <></>}
		</div>
	)
}

const VariousPricingTable = (props) => {
	return (
		<div>
			<h4>Divers</h4>
			<table className="table pricing-table">
				<thead className="thead-cng">
					<tr>
						<th scope="col">Nom</th>
						<th scope="col">Saison</th>
						<th scope="col">Tarif</th>
					</tr>
				</thead>
				<tbody>
					{getTableBody(props.data)}
				</tbody>
			</table>
			{props.isLoading ? <SmallLoadingComponent /> : <></>}
		</div>
	)
}

export class PricingTables extends Component {

	constructor(props) {
		super(props);

		this.state = {
			pricings: {},
			isLoading: false,
			error: undefined
		} 
	}

	componentDidMount() {
		this.getPricings();
	}

	getPricings() {
		this.setState({isLoading: true}, () => {
			PricingService.getPricings((pricings) => {
				this.setState({pricings: pricings, isLoading: false, error: undefined});
			}, error => {
				this.setState({isLoading: false, error: error, pricings: {}});
			})
		});
	}

	render() {
		return (
			<div className="row">
				<div className="col-md-6 col-12">
					<QuatwayPricingTable isLoading={this.state.isLoading} data={this.state.pricings.catway}/>
				</div>
				<div className="col-md-6 col-12">
					<TemporaryMemberPricingTable isLoading={this.state.isLoading} data={this.state.pricings.temporaryMemberPrices}/>
					<VariousPricingTable isLoading={this.state.isLoading} data={this.state.pricings.variousPrices} />
				</div>
			</div>
		)
	}
}