import React from 'react';

import "./404.css";

export const Error404 = () => {
	return (
		<div className="error-404 full-sized-page">
			<h1><i className="far fa-compass"></i> Un problème de boussole ?</h1>
			<h3> Cette page n'existe pas encore</h3>
			<a className="btn btn-primary" href="/">Retour à l'accueil</a>
		</div>
	)
} 