import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import history from '../common/history/';
import { Error404 } from '../error_pages/404';
import { PricingPage } from '../pricing_page';
import { PricingPath } from './base_router';

export const PricingRouter = () => {
	return (
		<Router history={history}>
			<Switch>
				<Route exact path={`${PricingPath}/`} component={PricingPage} />
				<Route component={Error404} />
			</Switch>
		</Router>		
	)
}
