import React from 'react';

import "./news_pages.css";
import { NewsList } from './news_list/news_list';

export const NewsListPage = () => {
	return (
		<div className="news-list-page">
			<h3>Actualités</h3>
			<NewsList />
		</div>
	)
}