import { ApiUrl } from "../config";
import { ApiService } from "./api_service";

export class DocumentsService extends ApiService {
    static getDirectories(callback, callback_error) {
        this.executeGet('files/directories', callback, callback_error);
    }

    static getFiles(parent, callback, callback_error) {
        if (parent === undefined) {
            this.executeGet(`files`, callback, callback_error);
        }
        else {
            this.executeGet(`files?directoryId=${parent}`, callback, callback_error);
        }
    }

    static renderUrl(fileUrl) {
        return `${ApiUrl}/${fileUrl}`
    }
}