import React from 'react';
import Lottie from 'react-lottie';
import "./loading_components.css";

export const LoadingComponent = () => {
  
	const lottieOptions = {
		loop: true,
		autoplay: true,
		animationData: require('./resources/loading-anim.json'),
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		} 
	};

	return (
		<Lottie options={lottieOptions}
		height={200}
		width={400}
		/>
	)
};

export const SmallLoadingComponent = () => {
	return (
		<div className="loading-container">
			<div className="spinner-border text-primary" role="status">
				<span className="sr-only">Loading...</span>
			</div>
		</div>
	)
}