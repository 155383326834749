import React from 'react';
import { Button } from 'react-bootstrap';

import "./social_button.css";

// TODO: These two uris must be fetched from the API
/// Url to CNG's Facebook page
const FacebookUrl = "https://www.facebook.com/cngiffaumont/";
/// CNG's Instagram URL
const InstagramUrl = "https://www.instagram.com/club_nautique_de_giffaumont/";

const SocialButton = (props) => {
	return (
		<a href={props.link} className={"social-button shadow-sm " + props.socialName} target="_blank" rel="noopener noreferrer">
			<i className={props.icon}/>
		</a>
	)
}

export const FacebookButton = () => {
	return (
		<SocialButton socialName={"facebook"} link={FacebookUrl} icon={"fab fa-facebook-f"}/>
	)
}

export const InstagramButton = () => {
	return (
		<SocialButton socialName={"instagram"} link={InstagramUrl} icon={"fab fa-instagram"}/>
	)
}

export const WebcamButton = (props) => {
	return (
		<Button className={"social-button shadow-sm webcam"} onClick={props.onClick}>
			<i className={"fas fa-video"} />
		</Button> 
	)
}