import React, { Component } from 'react';
import { DocumentsService } from '../services/documents_service';

import "./documents.css";
import { DocumentsList } from './documents_list';

export class DocumentsPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            files: [],
            directories: [],
            selectedDirectory: undefined,
            isLoading: true
        }
    }

    componentDidMount() {
        this.fetchDirectoriesAndFiles();
    }

    fetchDirectoriesAndFiles() {
        this.setState({files: [], directories: [], isLoading: true}, () => {
            DocumentsService.getDirectories((directories) => {
                DocumentsService.getFiles(this.state.selectedDirectory, (files) => {
                    this.setState({isLoading: false, directories: directories, files: files})
                }, (error) => this.setState({isLoading: false}));
            }, (error) => this.setState({isLoading: false}));
        })
    }

    selectDirectory(id) {
        this.setState({selectedDirectory: id}, () => this.fetchDirectoriesAndFiles())
    }

    render() {
        return (
            <div className="documents">
                <h1> Documents </h1> 

                <DocumentsList files={this.state.files} directories={this.state.directories} selectedDirectory={this.state.selectedDirectory} isLoading={this.state.isLoading} selectDirectory={(id) => this.selectDirectory(id)}/>
            </div>
        )
    }
}