import React, { Component } from 'react';
import { LoadingComponent } from '../common/loading_component';
import { Error404 } from '../error_pages/404';
import { ApiService } from '../services/api_service';
import { NewsService } from '../services/news_service';

export class NewsPage extends Component {

	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			error: undefined,
			news: undefined
		}
	}

	componentDidMount() {
		this.getNewsDetails();
	}

	getNewsDetails() {
		this.setState({isLoading: true, error: undefined}, () => {
			NewsService.getNewsById(this.props.match.params.id,
					(news) => this.setState({isLoading: false, error: undefined, news: news}),
					(error) => this.setState({news: undefined, isLoading: false, error: error})
			);
		})
	}

	render() {
		if (this.state.isLoading) {
			return (
				<LoadingComponent />
			)
		}
		if (this.state.error === undefined && this.state.news !== undefined) {
			return (
				<div className="news-page">
					<img src={`${ApiService.staticApiUrl}/${this.state.news.thumbnail_file_name}`} className="news-image" alt="news thumb"/>
					<div className="news-content">
						<h2>{this.state.news.title}</h2>
						<div dangerouslySetInnerHTML={{ __html: this.state.news.content }} />
					</div>
				</div>
			)
		}
		return (
			<Error404 />
		)
	}
}