import React, { Component} from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { Home } from '../home_page/home_page';
import history from '../common/history/';
import NavBar from './nav_bar'
import { Error404 } from '../error_pages/404';
import { NewsRouter } from './news_router';
import { PricingRouter } from './pricing_router';
import { SectionService } from '../services/section_service';
import { LoadingComponent } from '../common/loading_component';
import { DocumentsRouter } from './documents_router';

const SectionPage = (section, isLoading) => {
	return (
		<div style={{margin: "32px"}}>
			<h1> {section.name} </h1>
			{isLoading ? <LoadingComponent /> : <></>}
			<div dangerouslySetInnerHTML={{ __html: section.content }} />
		</div>
	)
}

export class BaseRouter extends Component {

	constructor(props) {
		super(props);

		const storedSections = SectionService.getStoredSections() || [];

		this.state = {
			sections: storedSections,
			isLoadingSections: false
		}
	}

	componentDidMount() {
		this.getSections()
	}

	getSections() {
		this.setState({isLoadingSections: true}, () => {
			SectionService.getSections((sections) => {
				SectionService.storeSections(sections);
				this.setState({sections: sections, isLoadingSections: false});
			}, error => {});	
		});
	} 

	render() {
		const sectionsRoute = this.state.sections.map(section => {
			return <Route path={section.path} component={() => SectionPage(section, this.state.isLoadingSections)} />
		});
		return (
			<div>
				<Router history={history}>
					<NavBar sections={this.state.sections}/>
					<div className="full-sized-page">
						<Switch>
							<Route exact path="/" component={Home}/>
							<Route path={NewsPath} component={NewsRouter} />
							<Route path={PricingPath} component={PricingRouter} />
							<Route path={DocumentsPath} component={DocumentsRouter} />
							{sectionsRoute}
							<Route component={Error404} />
						</Switch>
					</div>
				</Router>
			</div>
		)	
	} 
} 

export const NewsPath = "/news";
export const PricingPath = "/pricing";
export const DocumentsPath = "/documents";