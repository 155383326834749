import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import history from '../common/history/';
import { NewsListPage } from '../news_pages/news_list_page';
import { Error404 } from '../error_pages/404';
import { NewsPath } from './base_router';
import { NewsPage } from '../news_pages/news_page';

export const NewsRouter = () => {
	return (
		<Router history={history}>
			<Switch>
				<Route exact path={NewsPath} component={NewsListPage} />
				<Route exact path={`${NewsPath}/:id`} component={NewsPage} />
				<Route component={Error404} />
			</Switch>
		</Router>		
	)
}
