import React from 'react';
import { Carousel } from 'react-bootstrap';
import { DevelopmentInProgress } from '../config';
import { Link, Element } from "react-scroll";

import "./home_page.css";
import { WindWidget } from '../common/weather/wind';


export const Home = () => {
	return (
		<div className="home " id="home">
			<div className="home-background-container">
				<Carousel controls={true} indicators={true}>
					<Carousel.Item>
						<img
						className="d-block w-100 home-background"
						src="/background/1.jpg"
						alt="First slide"
						/>
					</Carousel.Item>
					<Carousel.Item>
						<img
						className="d-block w-100 home-background"
						src="/background/2.jpg"
						alt="Third slide"
						/>
					</Carousel.Item>
					<Carousel.Item>
						<img
						className="d-block w-100 home-background"
						src="/background/3.jpg"
						alt="Third slide"
						/>
					</Carousel.Item>
				</Carousel>
			</div>
			<Element className="home-container row" id="club-infos">
				<div className="col">
					<h1>
						<small class="text-muted">Bienvenue au Club Nautique de </small>
						<h1 className="animate__animated animate__fadeIn">Giffaumont</h1>
					</h1>
					<h4>
						Retrouvez-nous sur le port
						<h5 className="text-muted">
							<i className="fas fa-compass"></i> 2 chemin des millepertuis, 51290 Giffaumont
						</h5>
					</h4>
					<h5> Discutons ensemble au <i className="fas fa-phone fa-rotate-90"></i> <span className="cng-main-color">03.26.72.81.23</span> </h5>
					<h5> <i className="fas fa-envelope "></i> Notre email  <span className="cng-main-color">cngiffaumont@orange.fr</span> </h5>
					{
						DevelopmentInProgress === false ? <></> :
						<div class="alert alert-warning" role="alert" style={{width: "500px"}}>
							Le site est en cours d'élaboration, n'hésitez pas à nous faire des retours.
						</div>
					}
					
				</div>
				<div className="col-auto">
					<WindWidget />
				</div>

			</Element>
		</div>
	)
}