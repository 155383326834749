import React, { Component } from 'react';
import Moment from 'react-moment';
import { useHistory } from "react-router-dom";
import { NewsPath } from '../../routers/base_router';

import 'moment/locale/fr';

import "./news_list.css";
import { NewsService } from '../../services/news_service';
import { LoadingComponent } from '../../common/loading_component';
import { ApiService } from '../../services/api_service';
import { AllHtmlEntities } from 'html-entities';

const NewsThumbnail = (props) => {

	let history = useHistory();

	return (
		<div className="col-lg-4 col-sm-6 news-thumbnail" onClick={() => history.push(`${NewsPath}/${props.id}`)}>
			<div class="card">
				<img class="card-img-top" src={`${ApiService.staticApiUrl}/${props.thumbnail_file_name}`} alt="News thumbnail" />
				<div class="card-body">
                    <div className="headline-container">
                        <div className="news-title-thumb-container">
                            <h5 class="card-title news-title-thumb">{props.title}</h5>
                        </div>
                    </div>
					<p class="card-text news-content-preview">{`${AllHtmlEntities.decode(props.content.replace(/<\/?[^>]+(>|$)/g, ""), {level: 'html5'})}`}</p>
                    <div className="row">
                        <div className="col">
                            <div className="writer-container">
                                <img src={props.writer_pic} className="writer-pic" alt="writer profile pic"/><span className="writer-name">{props.writer_fname} {props.writer_lname}</span>
                            </div>        
                        </div>
                        <div className="col">
                            <p className="publish-time"> Publié <Moment locale="fr" interval={10} fromNow>{props.created_at}</Moment> </p>
                        </div>
                    </div>
				</div>
			</div>
		</div>
	)
}

export class NewsList extends Component {

	constructor(props) {
		super(props);

		this.state = {
			news: [],
			error: undefined,
			isLoading: false
		}
	}

	componentDidMount() {
		this.setState({isLoading: true}, () => {
			this.getNews();
		});
	}

	getNews() {
		this.setState({isLoading: true}, () => {
			NewsService.getNews(0, (news) => {
				this.setState({news: news, isLoading: false});
			}, (error) => {
				this.setState({error: error, isLoading: false})
			});	
		});
	} 

	render() {
		if (this.state.isLoading) {
			return (
				<LoadingComponent />
			)
		} 
		return (
			<div className="row">
				{this.state.news.map(news => {
					return <NewsThumbnail {...news} />
				})}
			</div>
		)
	}
}
