import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import "./nav_bar.css";
import { FacebookButton, InstagramButton, WebcamButton } from './components/social_button';
import { Webcam } from './components/webcam';

class NavBar extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showWebcamModal: false,
		}
	}

	isEnabled(link) {
		if (link === "/") {
			return this.props.location.pathname.endsWith(link);
		} 
		return this.props.location.pathname.startsWith(link);
	}

	createLink(path, name) {
		return (
			<Nav.Item>
				<Nav.Link className={this.isEnabled(path) === true ? "is-enabled" : ""} href={path}><span>{name}</span></Nav.Link>
			</Nav.Item>
		);
	}

	render() {
		const sections = this.props.sections.map(section => {
			return this.createLink(section.path, section.name);
		})
		return (
			<Navbar bg="light" expand="lg" className="shadow" fixed="top">
				<Navbar.Brand href="/" className="cng-brand inline-flex" >
					<img className="cng-logo" src="/logo.png" alt="logo"/> 
					<div className="ml-1 inline-flex vcentered">Club Nautique de <span className="ml-1 font-weight-bolder cng-main-color">Giffaumont</span></div>			
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="container-fluid">
						{this.createLink("/", "Accueil")}
						{this.createLink("/news", "Actualités")}
						{sections}
						{this.createLink("/pricing", "Tarifs")}
						{this.createLink("/documents", "Documents")}
						<Nav.Item className="ml-auto">
							<div className="social-container">
								<WebcamButton onClick={() => this.setState({showWebcamModal: true})} />
								<FacebookButton />
								<InstagramButton />
							</div>
						</Nav.Item>
					</Nav>
				</Navbar.Collapse>
				<Webcam show={this.state.showWebcamModal} onClose={() => this.setState({showWebcamModal: false})}/>
			</Navbar>
		)
	}
} 

export default withRouter(NavBar);