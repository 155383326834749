import { ApiService } from "./api_service";

export class SectionService extends ApiService {

	static storeSections(sections) {
		const sectionsWithoutContent = sections.map(section => {
			return {id: section.id, name: section.name, path: section.path};
		})

		localStorage.setItem("sections", JSON.stringify(sectionsWithoutContent));
	}

	static getStoredSections() {
		return JSON.parse(localStorage.getItem("sections"));
	}

	static getSections(callback, callback_error) {
		this.executeGet(`sections`, callback, callback_error);
	}
}