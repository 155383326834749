import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import history from '../common/history';
import { Error404 } from '../error_pages/404';
import { DocumentsPath } from './base_router';
import {  DocumentsPage } from '../documents_page';
export const DocumentsRouter = () => {
	return (
		<Router history={history}>
			<Switch>
				<Route exact path={DocumentsPath} component={DocumentsPage} />
                <Route component={Error404} />
			</Switch>
		</Router>		
	)
}
