import { ApiService } from "./api_service";

export class NewsService extends ApiService {

	static getNews(offset = 0, callback, callback_error) {
		this.executeGet(`news?offset=${offset}`, callback, callback_error);
	}

	static getNewsById(newsId, callback, callback_error) {
		this.executeGet(`news/${newsId}`, callback, callback_error);
	} 
}