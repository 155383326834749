import React from 'react';
import { SmallLoadingComponent } from '../common/loading_component';
import { DocumentsService } from '../services/documents_service';

const FileIcon = (file) => {
    var fileExt = file.path.split('.').pop();

    if (fileExt === "jpg" || fileExt == "gif") {
        return (<i class="far fa-file-image"></i>);
    }
    else if (fileExt === "pdf") {
        return (<i class="far fa-file-pdf"></i>);
    }
    else {
        return (<i class="fas fa-file"></i>)
    }
}

export const DocumentsList = (props) => {
    const directories = props.directories.map((directory) => {
        return (
            <tr className="item-row" onClick={() => props.selectDirectory(directory.id)}>
                <td><i class="fas fa-folder" style={{color: "#3498db"}}></i> {directory.display_name}</td>
            </tr>
        )
    })
    const files = props.files.map((file) => {
        return (
            <tr className="item-row" onClick={() => {
                const win = window.open(DocumentsService.renderUrl(file.path), "_blank");
                win.focus();
            }}>
                <td>{FileIcon(file)} {file.display_name}</td>
            </tr>
        )
    })
    return (
        <table className="table documents">
            <thead className="thead-light">
                <tr>
                    <th className="thead-title" scope="col">Nom</th>
                </tr>
            </thead>
            <tbody>
                {props.selectedDirectory === undefined ? <></> : <tr className="item-row" onClick={() => props.selectDirectory(undefined)}><td><i class="fas fa-long-arrow-alt-left"></i> Retour</td></tr> }
                {props.selectedDirectory === undefined ? directories : <></>}
                {files}
                {files.length === 0 && !props.isLoading ? <p> Aucun fichier disponible </p> : <></>}
            </tbody>
            <tfoot>
               <tr><td colSpan="1">{props.isLoading ? <SmallLoadingComponent /> : <></>}</td></tr>
            </tfoot>
        </table>
    )
}