import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import "./webcam.css";

const WebcamUrl = "http://content.meteobridge.com/cam/1274305596bea9ff2d3a246076974aff/camplus.jpg";

export const Webcam = (props) => {
	return (
		<Modal show={props.show} onHide={props.onClose} centered size={"lg"}>
			<Modal.Header closeButton>
				<Modal.Title> Webcam du port </Modal.Title>
			</Modal.Header>
			<Modal.Body className="webcam-body">
			<img src={WebcamUrl} width="100%" alt="webcam"/>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="light" onClick={props.onClose}>
					Fermer
				</Button>
			</Modal.Footer>
		</Modal>
	)
}