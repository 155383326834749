import React from 'react';

import "./wind.css";

export const WindWidget = () => {
    return (
        <div className="widget-container">
            <div className="header">
                <h5><i className="fas fa-sun"></i> La météo du port</h5>
            </div>
            <div className="body">
                <iframe src="/weather.html" width="170" height="317" style={{padding: 0, margin: 0, border: 0}}/>
            </div>
        </div>
    )
}